<script setup>
import {onMounted, watch} from 'vue'
import {Capacitor} from '@capacitor/core'
import {Preferences} from '@capacitor/preferences'
import {StatusBar} from '@capacitor/status-bar'
import vuetify from './plugins/vuetify'
import GlobalComponents from '@/components/GlobalComponents.vue'

const definirAppBar = () => {
  const isDark = vuetify.framework.theme.dark
  const backgroundColor = isDark ? '#272727' : '#1976D2'
  document.querySelector('meta[name="theme-color"]').setAttribute('content',  backgroundColor)
  if (Capacitor.getPlatform() === 'android') StatusBar.setBackgroundColor({color: backgroundColor})
  Preferences.set({key: 'cache_theme', value: (isDark ? '1' : '0')})
}

onMounted(() => {
  Preferences.get({key: 'cache_theme'}).then(v => {
    if (v.value !== null) vuetify.framework.theme.dark = v.value === '1'
  })

  if (Capacitor.isNativePlatform()) {
    import('@capacitor/push-notifications').then(({ PushNotifications }) => {
      PushNotifications.addListener('registration', async token => {
        const {httpSilent} = await import('@/plugins/axios')
        const {useSessionStore} = await import('@/store/Session')
        if (useSessionStore) {
          const sessionStore = useSessionStore()
          if (sessionStore.isAuthenticated) await httpSilent.post('/usuario/push-subscribe', {token: token.value})
        }
      })
    })
  }

  definirAppBar()
})

watch(() => vuetify.framework.theme.dark, definirAppBar)
</script>

<template>
  <v-app>
    <router-view></router-view>
    <GlobalComponents />
  </v-app>
</template>
