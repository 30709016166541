import { defineStore } from 'pinia'
import { Preferences } from '@capacitor/preferences'
import ImageHelper from '@/helpers/ImageHelper'

export const useFichaStore = defineStore('ficha', {
  state: () => ({
    cadastro: null, //Ficha global
    cadastroLoading: false,
    cadastroData: null, //Idade em segundos, desde a era Unix January 1, 1970 00:00:00 UTC
    foto: null, //Base64
    fotoLoading: false,
    assinatura: null, //Base64, utilizado somente no recadastramento.
  }),
  getters: {
    avatar: (state) => state.foto ? state.foto : 'img/avatar.svg'
  },
  actions: {
    async reloadCadastro() {
      try {
        this.cadastroLoading = true
        const {default: http} = await import('@/plugins/axios')
        const {data} = await http.get('/ficha');
        this.cadastro = data
        this.cadastroData = Math.floor(Date.now() / 1000)
      } finally {
        this.cadastroLoading = false
      }
    },
    async reloadFoto() {
      try {
        this.fotoLoading = true
        const {default: http} = await import('@/plugins/axios')
        const r = await http.get('/usuario/foto', {responseType: 'blob', validateStatus: status => status === 200 || status === 404})
        let imagem = null
        if (r.status === 200 && r.data) imagem = await ImageHelper.blobToDataURI_async(r.data)
        this.foto = imagem ? imagem : null
      } finally {
        this.fotoLoading = false
      }
    },
    destroy() {
      this.cadastro = null
      this.foto = null
      this.assinatura = null
    }
  }
})

export const restoreSavedFicha = async (pinia = null) => {
  const { value } = await Preferences.get({ key: 'pinia_ficha' })
  const store = useFichaStore(pinia)
  if (value) store.$patch(JSON.parse(value))
  store.$subscribe((mutation, state) => {
    if (state) Preferences.set({ key: 'pinia_ficha', value: JSON.stringify(state) })
    else Preferences.remove({ key: 'pinia_ficha' })
  }, { detached: true })
}
